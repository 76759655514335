<template>
    <div class="mc-container">
      <div class="header">
        <h4>Novo Laudo</h4>
        <b-button
            class="wh-button no-print"
            variant="primary"
            outline
            @click="openNewLaudo()"
          >
            Novo Laudo
          </b-button>
      </div>
      <b-row>
        <v-medical-table
          :medicalReports="laudosMedicos"
          @deletedLaudo="deletedLaudo"
        />
      </b-row>
  
      <v-medical-modal 
        :medicalReport="medicalReport" 
        @laudoCreated="laudoCreated"
        @laudoUpdated="laudoUpdated"
      />
    </div>
  </template>
    
    <script>
    import MedicalReportModal from '@/components/MedicalReports/MedicalReportModal.vue'
    import MedicalReportTable from '@/components/MedicalReports/MedicalReportTable.vue'
    import { EventBus } from '@/utils/eventBus'
  
    export default {
      name: 'MedicalReport',
      components: {
        'v-medical-modal': MedicalReportModal,
        'v-medical-table': MedicalReportTable,
      },
      async mounted() {
        await this.getMedicalReports()
        EventBus.$on('editMedicalReport', data => {
        this.medicalReport = data
      })
      },
      data() {
        return {
          search: null,
          medicalReport: null,
          laudosMedicos: [],
          laudosOptions: [],
          currentPage: null,
          perPage: 3,
          totalItems: null
        }
      },
      methods: {
        async getMedicalReports() {
          try {
            const response = await this.api.getDefaultMedicalReports()
            this.laudosMedicos = response.data
            this.laudosOptions = response.data.map(laudo => laudo.name)
          } catch (error) {
            this.$toast.error(error.message)
          } 
        },
      getDefaultForm() {     
          return {
            name: null,
            template: null,
          }
        },
      openNewLaudo() {
        this.medicalReport = this.getDefaultForm()
        this.$bvModal.show('medical-modal')
      },
  
      deleteModal(medicalReport) {
        this.$swal({
          icon: 'question',
          html: `<div class="body-alert">
            Você tem certeza que deseja remover o comentário personalizado 
            <span>${medicalReport.name}</span>?
            <p>Não será possível reverter essa ação.</p>
            </div>`,
          showCancelButton: true,
          confirmButtonColor: '#F63220',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
         }).then(async res => {
          if (res.isConfirmed) {
            await this.deleteTemplate(medicalReport)
          }
        })
      },
  
      deletedLaudo(laudo) {
        const index = this.laudosMedicos.findIndex(n => laudo.id === n.id) 
        this.laudosMedicos.splice(index, 1)
      },
      laudoCreated(laudo) {
        this.laudosMedicos.push(laudo)
      },
      laudoUpdated(laudo) {
        const laudoFound = this.laudosMedicos.find(n => laudo.id === n.id) 
        laudoFound.name = laudo.name
        laudoFound.template = laudo.template
        laudoFound.type = laudo.type
      },
      
      },
    }
    </script>
    
    <style lang="scss" scoped>
    .mc-container {
      min-height: 100vh;
      padding: 40px 20px;
    
      .header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    
        h4 {
          font-weight: 700;
        }
      }
    
      .search {
        border-radius: 8px !important;
        margin-bottom: 20px;
      }
    }
    </style>
    