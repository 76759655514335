<template>
    <b-table-simple striped class="table-eyecare">
      <thead>
        <tr>
          <th>Nome</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="medicalReport, index in medicalReports" :key="index"
        >
          <td>
            <b>{{ medicalReport.name }}</b>
            <div>{{ parseType(medicalReport.type) }}</div>
          </td>
          <td class="text-right">
            <div class="more-with-menu">
              <MoreVertical class="more-icon" />
              <div class="menu">
                <b-button
                  variant="link"
                  @click="editMedicalReport(medicalReport)"
                >
                  Editar
                </b-button>
                <b-button
                  variant="link remove mb-0"
                  @click="onDeleteClick(medicalReport)"
                >
                  Remover
                </b-button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </template>
  
  <script>
  import { EventBus } from '@/utils/eventBus'
  import MoreVertical from '@/assets/icons/more-vertical.svg'
  
  export default {
    name: 'MedicalReportTable',
    components: {
      MoreVertical,
    },
  
    props: {
      medicalReports: Array,
      modal: Boolean,
    },
  
    methods: {
      editMedicalReport(medicalReport) {
        EventBus.$emit('editMedicalReport', medicalReport)
        this.$bvModal.show('medical-modal')
      },
      onDeleteClick(medicalReport) {
        this.$bvModal
          .msgBoxConfirm(
            `Você tem certeza que deseja excluir ${medicalReport.name}? Não será possível reverter essa ação.`,
            {
              title: 'Excluir laudo',
              size: 'md',
              okTitle: 'Confirmar',
              cancelTitle: 'Cancelar',
              cancelVariant: 'danger',
              centered: true
            }
          )
          .then(confirm => {
            confirm && this.onDelete(medicalReport)
          })
      },
      async onDelete(medicalReport) {
        try {
          await this.api.deleteMedicalReport(medicalReport.id);
          this.$emit('deletedLaudo', medicalReport)
          this.$toast.success('Laudo removido com sucesso!')
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
      parseType(type) {
        const types = {
          'attendance': 'Atendimento',
          'exam': 'Exame',
        }
        return types[type] ?? type
      }
    },
  }
  </script>
  <style lang="scss" scoped>
    .type {
      font-size: 0.7rem;
    }
  </style>
  