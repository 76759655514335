<template>
    <b-modal
      id="medical-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal()"
    >
    <div class="modal-header">
      <div class="modal-title">
        Laudo Médico
      </div>
      <span class="icon-box"
        ><v-close class="icon stroke" @click="closeModal()"
      /></span>
    </div>
    <div class="around-content">
      <b-row>
          <b-col cols="12">
            <b-form-group>
                <label for="nome_laudo">Título do laudo</label>
                <input
                  id="nome_laudo"
                  autocomplete="off"
                  type="text"
                  placeholder="Descrever"
                  class="form-control"
                  v-model="form.name"
                />
                <div
                  v-if="validated  && !form.name"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group>
            <label for="type_laudo">Tipo do laudo</label>
              <multiselect
              id="role"
              v-model="form.role"
              :options="roles"
              track-by="value"
              label="name"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecione o tipo de laudo"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <v-chevron-down />
                </div>
              </template>

              <template slot="noOptions">
                Nenhuma opção
              </template>

              <template slot="noResult">
                Nenhum resultado
              </template>
            </multiselect>
            </b-form-group>
          </b-col>
        </b-row>
    <b-row>
        <b-col>
          <b-form-group>
            <label for="descricao_laudo">Texto do laudo</label>
              <VueEditor
                id="descricao"
                class="text-area"
                v-model="form.template"
                placeholder="Descrever "
                :editorToolbar="customToolbar"
              />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <div class="d-flex">
          <b-button
            v-if="form.id"
            variant="outline-primary"
            class="mr-2"
            size="lg"
            @click="
              defaultType = closeModal()"
          >
            Cancelar
          </b-button>
          
          <b-button
            v-if="form.id"
            variant="primary"
            size="lg"
            @click="
              defaultType = updateMedical()"
          >
            Salvar alterações
          </b-button>
          <b-button
            v-else
            variant="primary"
            size="lg"
            @click="createMedical()"
          >
            Adicionar
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

  <script>
  import { VueEditor } from "vue2-editor"
  import Close from '@/assets/icons/close.svg'
  import ChevronDown from '@/assets/icons/chevron-down.svg'

  export default {
    props: {
      medicalReport: Object,
      useProfessional: Boolean,
    },
    components: {
      VueEditor,
      'v-close': Close,
      'v-chevron-down': ChevronDown,
    },
    computed: {
    },
    data() {
      return {
        clinic: JSON.parse(localStorage.getItem('clinic')),
        form: {},
        validated: false,
        fields: this.getDefaultFields(),
        isLoading: false,
        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
        roles: [
          { value: 'attendance', name: 'Atendimento' },
          { value: 'exam', name: 'Exame' },
        ],
      }
    },
    methods: {
      getDefaultFields() {
        return {
          name: null,
          professional_id: null,
          template: null,
          type: null,
        }
      },
      isValidForm() {
        this.validated = true
        return this.form.name && this.form.template
        },
      clearForm() {
        this.form = this.getDefaultForm();
        this.$bvModal.hide('medical-modal')
      },
      closeModal(){
        this.$bvModal.hide('medical-modal')
      },
      getDefaultForm() {     
        return {
          name: null,
          template: null,
          type: null,
        }
      },
      async createMedical() {
        if (!this.isValidForm()) return
        const loading = this.$loading.show()
        try {
            const response = await this.api.storeMedicalReport({
              type: this.form.role.value,
              name: this.form.name,
              template: this.form.template,
              professional_id: null
            })
            this.clearForm();
            loading.hide();
            this.$toast.success('Laudo cadastrada com sucesso!')
            this.$bvModal.hide('medical-modal')
            this.$emit('laudoCreated', response.data)
        } catch (error) {
            loading.hide();
            this.$toast.error(error.message)
        } finally {
            loading.hide()
        }
      },
      async updateMedical() { 
        if (!this.isValidForm()) return
        const props = {
          name: this.form.name,
          template: this.form.template,
          type: this.form.role.value,
        }
        const loading = this.$loading.show()
        try {
            const response = await this.api.updateMedicalReport(this.form.id, props)
              this.$toast.success('Laudo atualizado com sucesso!')
              this.$bvModal.hide('medical-modal')
              loading.hide();
              this.$emit('laudoUpdated', response.data)
          } catch (error) {
              this.$toast.error(error.message)
          } finally {
              loading.hide()
          }
      },
    },
    watch: {
      medicalReport(newValue) {
      if (newValue) {
        this.form = {...newValue, role: this.roles.find(r => r.value === newValue.type)}
      }
    }
    }
  }
  </script>
  <style lang="scss">
#surgical-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    textarea {
      min-height: 200px;
      max-height: 1000px;
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
  